var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Tooltip, FormHelperText, Stack, TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
// translation
import { useLocales } from '../../locales';
export default function RHFDateTimePicker(_a) {
    var name = _a.name, helperText = _a.helperText, multiline = _a.multiline, rows = _a.rows, type = _a.type, inputRef = _a.inputRef, id = _a.id, label = _a.label, isEditReservation = _a.isEditReservation, isDisabled = _a.isDisabled, views = _a.views, other = __rest(_a, ["name", "helperText", "multiline", "rows", "type", "inputRef", "id", "label", "isEditReservation", "isDisabled", "views"]);
    var translate = useLocales().translate;
    var _b = useFormContext(), control = _b.control, setValue = _b.setValue;
    var _c = useState(false), isOpened = _c[0], setIsOpened = _c[1];
    var handlePickerChange = function (value) {
        setValue(name, value, { shouldValidate: true });
    };
    var currentDate = new Date();
    var roundedMinutes = Math.ceil(currentDate.getMinutes() / 15) * 15;
    var getPickerValue = function (value) {
        if (isOpened) {
            if (['endDate', 'startDate'].includes(name)) {
                currentDate.setMinutes(roundedMinutes);
            }
            else {
                currentDate.setMinutes(currentDate.getMinutes());
            }
            if (value !== null && value !== undefined) {
                return new Date(value);
            }
            setValue(name, new Date(currentDate), { shouldValidate: true });
            return new Date(currentDate);
        }
        return value ? new Date(value) : null;
    };
    return (_jsx(Controller, { name: name, defaultValue: null, control: control, render: function (_a) {
            var value = _a.field.value, error = _a.fieldState.error;
            var pickerValue = getPickerValue(value);
            return (_jsxs(Stack, __assign({ sx: { display: 'flex', flexDirection: 'column', gap: 0.2 } }, { children: [_jsx(Tooltip, __assign({ title: "".concat(translate(label)) }, { children: _jsx(MobileDateTimePicker, { ampm: false, label: "".concat(translate(label)), value: pickerValue, onChange: handlePickerChange, onOpen: function () { return setIsOpened(true); }, renderInput: function (props) { return _jsx(TextField, __assign({}, props)); }, views: views, inputFormat: 'HH:mm' }) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] })));
        } }));
}

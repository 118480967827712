import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var departementPermission = function (user) {
    var hasPermissionListDepartement = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.DEPARTMENT, MethodCode.LIST);
    var hasPermissionViewDepartement = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.DEPARTMENT, MethodCode.VIEW);
    var hasPermissionEditDepartement = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.DEPARTMENT, MethodCode.EDIT);
    var hasPermissionCreateDepartement = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.DEPARTMENT, MethodCode.CREATE);
    var hasPermissionDeleteDepartement = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.DEPARTMENT, MethodCode.DELETE);
    return {
        hasPermissionListDepartement: hasPermissionListDepartement,
        hasPermissionViewDepartement: hasPermissionViewDepartement,
        hasPermissionEditDepartement: hasPermissionEditDepartement,
        hasPermissionCreateDepartement: hasPermissionCreateDepartement,
        hasPermissionDeleteDepartement: hasPermissionDeleteDepartement,
    };
};
export default departementPermission;

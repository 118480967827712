import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { deleteDepartementsWorktime, getAllDepartementsWorktime, createDepartementsWorktime, getOneDepartementsWorktime, updateDepartementsWorktime, } from './action';
var initialState = {
    status: IStatus.IDLE,
    departementsWorktimes: { docs: [], meta: {} },
    departementWortime: {},
};
// ----------------------------------------------------------------------
var slice = createSlice({
    name: 'departementWortime',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(createDepartementsWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createDepartementsWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departementWortime = payload;
        })
            .addCase(createDepartementsWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateDepartementsWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateDepartementsWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departementWortime = payload;
        })
            .addCase(updateDepartementsWorktime.rejected, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneDepartementsWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneDepartementsWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departementWortime = payload;
        })
            .addCase(getOneDepartementsWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAllDepartementsWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllDepartementsWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departementsWorktimes = payload;
        })
            .addCase(getAllDepartementsWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteDepartementsWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteDepartementsWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departementsWorktimes.docs = state.departementsWorktimes.docs.filter(function (department) { return (department === null || department === void 0 ? void 0 : department._id) !== payload.departementId; });
        })
            .addCase(deleteDepartementsWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;

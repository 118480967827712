export { default as useAnalyticsPermission } from './useAnalyticsPermission';
export { default as useBalancePermission } from './balance';
export { default as useCalendarPermissions } from './calendar';
export { default as useCallPermissions } from './calls';
export { default as useCodesPermissions } from './codes';
export { default as useExpensesPermissions } from './expenses';
export { default as useKpisPermissions } from './kpis';
export { default as useNotesPermissions } from './notes';
export { default as useNotifsPermissions } from './notifications';
export { default as useStatClientAnswersPermissions } from './statClientAnswers';
export { default as useTodosPermissions } from './todos';
export { default as useCashRegisterPermissions } from './cashRegister';
export { default as useUserPermissions } from './user';
export { default as useOfficesPermissions } from './office';
export { default as useFolderPermissions } from './folder';
export { default as useStatClientPermissions } from './statClient';
export { default as useAgentPerformancePermissions } from './agentPerformance';
export { default as useBonusAmountPermissions } from './bonusAmount';
export { default as usePaymentNotesPermissions } from './paymentNotes';
export { default as useDepartementPermission } from './departement';

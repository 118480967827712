import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createDepartements, deleteDepartements, getAllDepartements, getOneDepartements, updateDepartements, } from './action';
var initialState = {
    status: IStatus.IDLE,
    departements: { docs: [], meta: {} },
    departement: {},
};
// ----------------------------------------------------------------------
var slice = createSlice({
    name: 'departement',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(createDepartements.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createDepartements.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departement = payload;
        })
            .addCase(createDepartements.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateDepartements.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateDepartements.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departement = payload;
        })
            .addCase(updateDepartements.rejected, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneDepartements.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneDepartements.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departement = payload;
        })
            .addCase(getOneDepartements.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAllDepartements.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllDepartements.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departements = payload;
        })
            .addCase(getAllDepartements.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteDepartements.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteDepartements.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.departements.docs = state.departements.docs.filter(function (department) { return (department === null || department === void 0 ? void 0 : department._id) !== payload.departementId; });
        })
            .addCase(deleteDepartements.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;

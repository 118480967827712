var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Tooltip } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export default function RHFTextField(_a) {
    var _this = this;
    var name = _a.name, helperText = _a.helperText, multiline = _a.multiline, rows = _a.rows, type = _a.type, inputRef = _a.inputRef, id = _a.id, label = _a.label, defaultValue = _a.defaultValue, other = __rest(_a, ["name", "helperText", "multiline", "rows", "type", "inputRef", "id", "label", "defaultValue"]);
    var translate = useLocales().translate;
    var control = useFormContext().control;
    var handleWheel = function (event) {
        event.preventDefault();
    };
    return (_jsx(Controller, { name: name, control: control, defaultValue: defaultValue, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(TextField, __assign({}, field, { name: name, label: "".concat(translate(label), " "), onBlur: field.onBlur, InputProps: {
                        onWheel: handleWheel,
                        inputProps: {
                            min: typeof other.min === 'string' ? parseFloat(other.min) : other.min,
                        }
                    }, defaultValue: defaultValue, fullWidth: true, onWheel: handleWheel, value: field.value, multiline: multiline !== undefined && true, rows: rows, onChange: function (e) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            field.onChange(e);
                            return [2 /*return*/];
                        });
                    }); }, error: !!error, helperText: error && "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) }, other, { type: type })) })));
        } }));
}
